import React, { Fragment, useState, useEffect } from "react";
import logo from "../images/logo.png";
import { Transition, Menu } from "@headlessui/react";

function Header() {
  const [top, setTop] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      }`}
    >   
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex justify-between h-16 md:h-20 ">
          {/* Site branding */}
          <div>
          <img
                              className="object-cover "
                              height="200"
                              width="200"
                              src={logo}
                              alt=""
                            />
          </div>

          {/* Site navigation */}
          <nav className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex md:hidden ">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button>
                      {" "}
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        type="button"
                        className="bg-[#005A98] inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-[#548CCF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Open main menu</span>
                        {!isOpen ? (
                          <svg
                            className="block h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 6h16M4 12h16M4 18h16"
                            />
                          </svg>
                        ) : (
                          <svg
                            className="block h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        )}
                      </button>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                        <Menu.Item>
                            {({ active }) => (
                              <a
                                href="https://blog.hulacorn.com"
                                target="_blank"
                                rel="noreferrer"
                                className={`${
                                  active
                                    ? "bg-[#005A98] text-white"
                                    : "text-[#005A98]"
                                } group flex rounded-md items-center w-full px-2 py-2 text-lg`}
                              >
                                Blog
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#products"
                                className={`${
                                  active
                                  ? "bg-[#005A98] text-white"
                                  : "text-[#005A98]"
                                } group flex rounded-md items-center w-full px-2 py-2 text-lg`}
                              >
                                Public projects
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#mentorship"
                                className={`${
                                  active
                                  ? "bg-[#005A98] text-white"
                                  : "text-[#005A98]"
                                } group flex rounded-md items-center w-full px-2 py-2 text-lg`}
                              >
                                Free Mentorship
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#about"
                                className={`${
                                  active
                                  ? "bg-[#005A98] text-white"
                                  : "text-[#005A98]"
                                } group flex rounded-md items-center w-full px-2 py-2 text-lg`}
                              >
                                About Us
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="flex items-center">
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {/* <a
                        href="#"
                        className=" hover:bg-gray-700  text-transparent bg-clip-text bg-gradient-to-br from-blue-500 to-teal-400 px-3 py-2 rounded-md text-lg font-medium"
                      >
                        Due Diligence
                      </a> */}

<a
                        href="https://blog.hulacorn.com"
                        target="_blank"
                        rel="noreferrer"
                        className=" hover:bg-gray-700  text-transparent bg-clip-text bg-[#005A98] px-3 py-2 rounded-md text-lg font-medium"
                      >
                        Blog
                      </a>
                      

                      <a
                        href="#products"
                        className=" hover:bg-gray-700  text-transparent bg-clip-text bg-[#005A98] px-3 py-2 rounded-md text-lg font-medium"
                      >
                        Public Products
                      </a>

                      <a
                        href="#mentorship"
                        className=" hover:bg-gray-700  text-transparent bg-clip-text bg-[#005A98] px-3 py-2 rounded-md text-lg font-medium"
                      >
                        Free Mentorship
                      </a>
                      <a
                        href="#about"
                        className=" hover:bg-gray-700  text-transparent bg-clip-text bg-[#005A98] px-3 py-2 rounded-md text-lg font-medium"
                      >
                        About Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Transition
              show={isOpen}
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {(ref) => <div className="md:hidden" id="mobile-menu"></div>}
            </Transition>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
