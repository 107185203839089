import React from 'react';

// import Header from '../partials/Header';
// import HeroHome from '../partials/HeroHome';
// import FeaturesHome from '../partials/Features';
import Team from '../partials/Team';
import Mentor from '../partials/Mentor';
import Intercom from '@intercom/messenger-js-sdk';
// import FeaturesBlocks from "../partials/FeaturesBlocks";
// import Testimonials from "../partials/Testimonials";
// import Newsletter from "../partials/Newsletter";
// import Footer from "../partials/Footer";

function Home() {
  Intercom({
    app_id: 'w85aioms',

  });

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}

        <Mentor />
        <Team />
        {/* <FeaturesBlocks /> */}
      </main>

      {/*  Site footer */}
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
