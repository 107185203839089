/* This example requires Tailwind CSS v2.0+ */
import eugen from "../images/eugen.png";
import roberto from "../images/roberto.png";
import podge from "../images/podge.png";
import luciano from "../images/luciano.jpg";

import {
  AnnotationIcon,
  MailIcon,
} from "@heroicons/react/outline";


const persons = [
  {
    id: 1,
    name: "Luciano Mammino",
    imageUrl: luciano,
    role: "Interviewing, Pair Programming, Mentoring",
  },
  {
    id: 2,
    name: "Padraig O'Brien",
    imageUrl: podge,
    role: "CV/Resume review, Interviewing, Mentoring",
  },
  {
    id: 1,
    name: "Roberto Gambuzzi",
    imageUrl: roberto,
    role: "Interviewing, Pair Programming, Mentoring",
  },
  {
    id: 1,
    name: "Eugen Serbanescu",
    imageUrl: eugen,
    role: "Interviewing, Pair Programming, Mentoring",
  },
  // More items...
];



export default function Mentor() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24" id="mentorship">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Free mentorship for getting your first or next job
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            We have a combined 60 years experience of engineering experience. We
            have interviewed 1000's of people over the years, We are happy to
            share what we know with you, for free{" "}
          </p>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Contact us
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                We provide advice and direction on resumes/cv's , interviewing
                from a culture and technical aspect.
              </p>

              <dl className="mt-10 space-y-10">
                <div key="1" className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#005A98] text-white">
                      <AnnotationIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Book a meeting
                    </p>
                  </dt>
                  <p className="mt-2 ml-16 text-base text-gray-500">
                    <span className="r leading-relsubpixel-antialiased font-bold tracking-tighteaxed bg-clip-text ">
                      Book a 30 minute meeting with us on{" "}
                      <a
                        href="https://calendly.com/hulacorn/30min/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline decoration-sky-500"
                        aria-label="Cruip"
                      >
                        Calendly
                      </a>{" "}
                      so we can get to know you and best figure out how we can
                      help you.
                    </span>
                  </p>
                </div>
                <div key="2" className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#005A98] text-white">
                      <MailIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      Contact
                    </p>
                  </dt>

                  <p className="mt-2 ml-16 text-base text-gray-500">
                    <span className="r leading-relsubpixel-antialiased font-bold tracking-tighteaxed bg-clip-text ">
                      If you prefer to do async, feel free to email us too @
                      mentorship@hulacorn.com
                    </span>
                  </p>
                </div>
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <div>
                <div className="bg-gray-100 shadow overflow-hidden rounded-md">
                  <ul className="divide-y divide-gray-200">
                    {persons.map((person) => (
                      <li key={person.id} className="px-6 py-4">
                        <div className="space-y-4 grid grid-cols-3 gap-6 space-y-0 lg:gap-8">
                          <div className="pl-16 h-4 aspect-w-1 aspect-h-1 ">
                            <img
                              className="object-cover shadow-lg rounded-lg"
                              height="75"
                              width="75"
                              src={person.imageUrl}
                              alt=""
                            />
                          </div>
                          <div className="sm:col-span-2">
                            <div className="space-y-1">
                              <div className="text-lg font-medium space-y-1">
                                <h3>{person.name}</h3>
                                <p className="text-[#005A98]">{person.role}</p>
                              </div>
                              <div className="text-lg">
                                <p className="text-gray-500">{person.bio}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
